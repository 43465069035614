import * as React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useIntl } from "react-intl";

const languages = [
  { locale: `en`, name: `English` },
  { locale: `fr`, name: `Français` },
];

const Languages = () => {
  const { locale: activeLocale } = useIntl();

  return (
    <ul className="navigation-languages">
      {languages.map(({ locale, name }) => (
        <li key={locale}>
          <AniLink
            to={`/${locale}/`}
            hrefLang={locale}
            title={name}
            cover
            direction="right"
            duration={1}
            bg="#cdd4ba"
            data-active={locale === activeLocale ? true : null}
          >
            {locale.toUpperCase()}
          </AniLink>
        </li>
      ))}
    </ul>
  );
};

export default Languages;
