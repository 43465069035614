import * as React from "react";
import { useIntl } from "react-intl";
import LocalizedLink from "./localized-link";
import Icon from "./icon";
import Languages from "./languages";
import Menu from "./menu";

const Navigation = ({ pages, isIndex }) => {
  const [menu, setMenu] = React.useState(null);
  const { formatMessage, locale } = useIntl();
  const handleClick = () => setMenu(!menu ? true : null);

  React.useEffect(() => {
    const handleKeyDown = (event) => event.key === `Escape` && setMenu(null);
    window.addEventListener(`keydown`, handleKeyDown);
    return () => window.removeEventListener(`keydown`, handleKeyDown);
  }, []);

  return (
    <nav className="navigation" aria-label={formatMessage({ id: `mainNav` })}>
      <div className="navigation-buttons">
        <button onClick={handleClick} aria-controls="menu">
          <Icon name="hamburger" />
        </button>
        <div
          id="menu"
          className="navigation-menu"
          aria-modal={menu}
          tabIndex={-1}
          role={menu ? `dialog` : null}
          aria-label={formatMessage({ id: `menu` })}
        >
          <div className="navigation-grid">
            <div className="navigation-buttons">
              <button onClick={handleClick}>
                <Icon name="close" />
              </button>
            </div>
            <a
              className="navigation-logo"
              href={`https://www.picture-organic-clothing.com/${locale}`}
            >
              <Icon name="picture" />
            </a>
            <Languages />
            <Menu pages={pages} />
          </div>
        </div>
        {!isIndex && (
          <LocalizedLink to="/">
            <Icon name="back" />
          </LocalizedLink>
        )}
      </div>
      <a
        className={`navigation-logo${!isIndex ? ` lg:hidden` : ``}`}
        href={`https://www.picture-organic-clothing.com/${locale}`}
      >
        <Icon name="picture" />
      </a>
    </nav>
  );
};

export default Navigation;
