import * as React from "react";
import LocalizedLink from "./localized-link";
import Icon from "./icon";

const Menu = ({ pages, handleLinkClick }) => (
  <ul className="navigation-links">
    {pages.map(({ id, frontmatter: { icon, permalink, title } }) => (
      <li key={id}>
        <LocalizedLink to={`/${permalink}`} onClick={handleLinkClick}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </LocalizedLink>
        <figure aria-hidden="true">
          <Icon name={icon} />
        </figure>
      </li>
    ))}
  </ul>
);

export default Menu;
