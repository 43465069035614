import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

const query = graphql`
  query {
    image: file(absolutePath: { regex: "/og-image.jpg/" }) {
      publicURL
    }
  }
`;

const Seo = ({ title, description, image }) => {
  const data = useStaticQuery(query);
  const { locale, formatMessage } = useIntl();
  const defaultTitle = `Picture Sustainability`;
  const defaultDescription = formatMessage({ id: `description` });
  const defaultImage = data.image.publicURL;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${process.env.GATSBY_URL}${image || defaultImage}`,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
      htmlAttributes={{ lang: locale, dir: `ltr` }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <script
        src="https://cdn.usefathom.com/script.js"
        data-site="GQXFODPH"
        defer
      ></script>
    </Helmet>
  );
};

export default Seo;
