import * as React from "react";
import Grading from "./grading";

const Header = ({ title, intro, grading }) => (
  <header className="header">
    <div className="header-title">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      {intro && <p>{intro}</p>}
    </div>
    {grading && <Grading grading={grading} isHeaderGrading />}
  </header>
);

export default Header;
